<template>
	<div class="baseItemBox text-white">
		<div>
			<BlockBox title="回收物品重量统计(kg)" enTitle="Recovery" height="306">
				<template #content>
					<div class="tabBox" >
						<div class="menuBox flex align-center justify-center">
							<div class="menuItem " :class="index==zlDataTabIndex?'checked':''"
								v-for="(item,index) in zlDataTab" :key="index" @click="zlDataTabClck(index)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div style="height:200px;padding:0 0;box-sizing: border-box">
						<dv-scroll-board :config="zlDataConfig" style="width:100%;height:230px" />
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<BlockBox title="回收门店收货明细" enTitle="Harvest Details" height="395">
				<template #content>
					<div style="padding:0 0;box-sizing: border-box">
						<div class="custlbBox" style="height:355px;">
							<dv-scroll-board :config="mdshmxConfig" style="width:100%;height:355px" />
						</div>
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<BlockBox title="即时下单" enTitle="lmmediate order" height="256">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div class="shtjBox margin-top-10" >
							<!-- <div class="swiper">
								<div class="swiper-wrapper">
									<div class="swiper-slide" v-for="(obj,index) in 15" :key="index">
										<div class="shtjItem base-font-12">
											55555555555555555555
										</div>
									</div>
								</div>
							</div> -->
							<div class="swiper" >
								<div class="swiper-wrapper">
									<div class="swiper-slide" v-for="(obj,index) in dkList" :key="index">
										<div class="shtjItem base-font-14">
											<el-image class="notcieIcon" :src="notice"></el-image>用户<span
												class="text-blue">{{obj.name}}</span>于{{obj.createTime}}<span
												class="text-green">下单成功</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</BlockBox>
		</div>

	</div>
</template>

<script>
	import echartMixins from "@/utils/resizeMixins";
	import BlockBox from '@/views/components/blockBox'
	import notice from '@/assets/img/notice.png'
	import 'swiper/swiper-bundle.min.css'
	import Swiper from 'swiper/swiper-bundle';
	import {
		selectWeightCate,
		selectDetailsAndNewOrder
	} from "@/api/api";
	export default {
		name: "cityGreenLand",
		components: {
			BlockBox
		},
		mixins: [echartMixins],
		data() {
			return {
				item: {},
				dkList: [],
				// mrhnDay: ['12/05', '12/06', '12/07', '12/08', '12/09'],
				// 调整开始************
				notice,
				zlDataTab: [{
					id: "1",
					name: "全年"
				}, {
					id: "2",
					name: "本月"
				}, {
					id: "3",
					name: "全天"
				}],
				zlDataTabIndex: 0,
				zlDataConfig: {
					header: ['名称', '数量'],
					indexHeader: "序号",
					data: [],
					index: true,
					columnWidth: [60, 240]
				},
				mdshmxConfig: {
					header: ['时间', '门店', '品类', '重量', '金额', '减碳量kg'],
					indexHeader: "序号",
					data: [],
					index: true,
					columnWidth: [70, 110, 100, 100]
				},
				swiperShow:false,
				timerLeft:null,
			}
		},
		
		mounted() {
			this.changeData();
			this.changeDataTwo();
			this.timerLeft = setTimeout(() => {
			  this.changeData();
			  this.changeDataTwo();
			}, 60000);
	
		},
		  beforeDestroy() {
		    clearTimeout(this.timerLeft);
		  },
		methods: {
			changeData() {
				let _self = this
				selectWeightCate(this.zlDataTab[this.zlDataTabIndex].id).then(res => {
					let list = [];
					if (res.data != null && res.data.length > 0) {
						res.data.forEach(obj => {
							list.push([obj.name,'<span class="text-color-yellow">'+obj.money+'</span>','<span class="text-color-blue">'+obj.value+'</span>']);
						})
					}
					console.log(list,'list')
					_self.zlDataConfig = {
						header: ['名称','金额(元)','数量(kg)'],
						indexHeader: "序号",
						data: list,
						index: true,
						columnWidth: [60, 140]
					};
				});
			},
			changeDataTwo() {
				let _self = this
				selectDetailsAndNewOrder().then(res => {
					let list = [];
					_self.dkList = res.data.newOrderList;
					_self.swiperShow=true
					_self.$nextTick(()=>{
						_self.initSwiper()
					})
					if (res.data.weightList != null && res.data.weightList.length > 0) {
						res.data.weightList.forEach( obj => {
							
							list.push([obj.createTimeStr, obj.allianceName, obj.categoryName,'<div class="text-color-blue text-center">'+obj.weight+'</div>',
								'<div class="text-color-yellow text-center">'+obj.totalMoney+'</div>', '<div class="text-color-green text-center">'+obj.dayWeight+'</div>'
							]);
							
						})
					}
					_self.mdshmxConfig = {
						header: ['时间', '门店', '品类', '重量(kg)', '金额(元)', '今日收货量(kg)'],
						data: list,
						rowNum:7,
						columnWidth: [100,65,54,60,63]
					};
					
					
						
				});
			},
			// 方法开始
			zlDataTabClck(index) {
				this.zlDataTabIndex = index;
				this.changeData();
			},
			initSwiper() {
				new Swiper('.swiper', {
					autoplay: true, //可选选项，自动滑动
					direction: 'vertical',
					height: 200, //你的slide高度
					slidesPerView: 4,
				})
			}

		}
	}
</script>
<style>
	.dv-scroll-board .rows .ceil{padding:0 2px!important;}
	.dv-scroll-board .header .header-item{padding:0 2px!important;}
</style>

<style lang="scss">
	.baseItemBox {
		min-height: 500px;
		padding: 14px 15px 15px 15px;
		box-sizing: border-box;

		.shtjItem {
			width: 100%;
			height: 38px;
			line-height: 38px;
			border: 1px solid #155898;
			line-height: 38px;
			padding: 0 5px;
			box-sizing: border-box;
			cursor: pointer;
		}

		.shtjItem:hover {
			background: #155898;
		}

		.shtjBox {
			height: 200px;

			& .swiper {
				height: 200px;
			}
		}
	}
	.custlbBox{
		::v-deep .dv-scroll-board {
			.rows {
				.ceil{padding:0 5px!important;}
			}
		}
	}


	.hnzbLable {
		margin-top: 10px;
	}

	.hnzbLable .itemLable {
		line-height: 1;
		border: 1px solid;
		color: #2196f3;
		font-size: 12px;
		padding: 5px 7px;
		border-radius: 1px;
	}

	.hnzbLable .itemLable+.itemLable {
		margin-left: 7px;
	}

	.hnzbLable .itemLable.checked {
		background: #006AF6;
		color: #FFFFFF;
		border-color: #006AF6;
	}

	//调整
	.tabBox .menuBox {
		height: 32px;
		font-weight: normal;
		font-size: 12px;
	}

	.tabBox .menuBox .menuItem {
		padding: 0 12px;
		height: 20px;
		line-height: 20px;
		cursor: pointer;
	}

	.tabBox .menuBox .menuItem.checked {
		background: #2196f3;
	}

	.notcieIcon {
		width: 16px;
		height: 16px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
	}
</style>