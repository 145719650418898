<template>
	<div class="baseBody">
		<ScaleBox>
			<div class="main-wraper relative">
				<div class="dateWeek"><DynamicDate></DynamicDate></div>
				<div class="fullBox"><ScreenfullBox></ScreenfullBox></div>
				<div class="baseTitleContent">
					<div class="baseTitleMid">
						<div class="top">
							<div class="topLine">
								<div class="title text-center base-font-65 text-white text-bold" >31回收大数据统计</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex align-center justify-between">
					<div>
						<div class="baseTitleLeft"></div>
					</div>
					<div>
						<div class="baseTitleRight"></div>
					</div>
				</div>
				<div class="">
					<el-row >
						<el-col :span="6">
							<LeftContent></LeftContent>
						</el-col>
						<el-col :span="12">
							<MidContent></MidContent>
						</el-col>
						<el-col :span="6">
							<RightContent></RightContent>
						</el-col>
					</el-row>
				</div>
			</div>
		</ScaleBox>
	</div>
</template>

<script>
	import DynamicDate from "../components/dynamicDate/index.vue";
	import ScreenfullBox from "../components/screenfull/index.vue";
 	import ScaleBox from "../components/sacleBox/index.vue";
	import LeftContent from './components/leftContent.vue'
	import RightContent from './components/rightContent.vue'
	import MidContent from './components/midContent.vue'
	export default {
		components: {
			LeftContent,
			RightContent,
			MidContent,
			ScaleBox,
			DynamicDate,
			ScreenfullBox
		},
		data() {
			return {
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="scss">
	.base-font-65{font-size:70px;}
	.baseBody{
		width:100%;height: 100vh;overflow: hidden;position:relative;
		font-size:14px;
		background:#000208;
		position:relative;
		.main-wraper{
			user-select: none;
			width:1920px;height:1080px;
			margin:0 auto;
			background: #02081a url('../assets/images/sytBg2.png') center no-repeat;
			background-size: cover;
			box-sizing: border-box;
		}
	
	}
	.dateWeek{position:absolute;top:0;left:0;width:200px;height:50px;color:#ffffff;}
	.fullBox{position:absolute;top:0;right:0;width:200px;height:50px;color:#ffffff;z-index: 9999;}
	.nyBaseHeadTop {
		width: 100%;
		height: 80px;
		background: url('../assets/images/titleBg.png') top center no-repeat;
		background-size: auto 80px
	}

	.base-headTit {
		font-size: 30px;
		font-weight: bold;
		box-sizing: border-box;
		padding-top: 24px;
	}

	.baseTitleLeft {
		width: 644px;
		height: 55px;
		background: url('../assets/images/titleLeft.png') top right no-repeat;
		background-size: auto 55px
	}

	.baseTitleRight {
		width: 644px;
		height: 55px;
		background: url('../assets/images/titleRight.png') top left no-repeat;
		background-size: auto 55px
	}

	.baseBody .baseTitleContent {
		width: 100%;
		height: 100px;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
	}

	.baseBody .baseTitleContent .baseTitleMid {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../assets/images/headtitileBg3.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .top {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../assets/images/headtitileBg2.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .topLine {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../assets/images/titleBg4.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .title {
		width: 402px;
		height: 70px;
		margin: 0 auto;
		font-size: 43px;
		font-weight: bold;
		line-height: 70px;
		background: url('../assets/images/headtitileBg1.png') top left no-repeat;
		background-size: auto 70px
	}
</style>
