<template>
	<div class="base-font-16 text-blue fullScreenTxt"
		style="padding-top:25px;box-sizing: border-box;padding-right:20px;">
		<div class="flex align-center justify-end pointer" v-if="isFullscreen==false" @click="handleFullscreen">
			<i class="el-icon-full-screen"></i>
			<div style="margin-left:5px;">全屏</div>
		</div>
		<div class="flex align-center justify-end pointer" v-else @click="handleFullscreen">
			<i class="el-icon-switch-button"></i>
			<div style="margin-left:5px;">退出全屏</div>
		</div>
	</div>
</template>

<script>
	import screenfull from 'screenfull'
	export default {
		data() {
			return {
				isFullscreen: false
			}
		},
		mounted() {
			this.init()
			this.showkey()
		},
		beforeDestroy() {
			// this.destroy();
		},
		created: function() {
		},
		methods: {
			handleFullscreen() {
				if (screenfull.enabled) {
					this.$message({
						message: "不支持全屏!",
						type: "warning",
					});
					return false;
				}
				screenfull.toggle();
			},

			change() {
				this.isFullscreen = screenfull.isFullscreen;
			},
			init() {
				if (!screenfull.enabled) {
					screenfull.on("change", this.change);
				}

			},
			destroy() {
				if (!screenfull.enabled) {
					screenfull.off("change", this.change);
				}
			},
			showkey() {
				document.onkeydown = function(e) {
					console.log(e)
					let key = window.event.keyCode;
					if (key == 122 || key == 27) {
						window.event.returnValue = false;
						this.handleFullscreen()
					}
				};
			},
		}
	}
</script>

<style>
	.fullScreenTxt:hover {
		color: #48aeff !important;
	}
</style>
