<template>
	<div class="com-right-content text-white">
		<div>
			<BlockBox title="门店经营数据" enTitle="Store operation data" height="306">
				<template #content>
					<div style="padding:20px 15px 0 15px;box-sizing: border-box">
						<div id="chartCddjtj" style="height:245px;"></div>
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<BlockBox title="昨日减碳总量(kg)" enTitle="Total carbon reduction yesterday " height="176">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div class="text-center text-green bigNum" style="height:130px;" v-if="item.yesterdayJtl">
							<!-- {{formatter(item.yesterdayJtl)}} -->{{item.yesterdayJtl}}
						</div>
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<BlockBox title="累积减碳总量(kg)" enTitle="Cumulative Carbon Reduction" height="176">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div class="text-center  bigNum" style="height:130px;color:#ff94a3;" v-if="item.allJtl">
							<!-- {{formatter(item.allJtl)}} -->{{item.allJtl}}
						</div>
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<BlockBox title="项自介绍" enTitle="Proiect introduction" height="285">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div class="text-center videoBox" style="height:265px;">
							<iframe
								:src="item.url"
								scrolling="no" border="0" frameborder="no" framespacing="0"
								allowfullscreen="true"></iframe>
						</div>
					</div>
				</template>
			</BlockBox>
		</div>

	</div>
</template>

<script>
	import echartMixins from "@/utils/resizeMixins";
	import BlockBox from '@/views/components/blockBox'
	import {
    rightDataNew
	} from "@/api/api";
	export default {
		name: "cityGreenLand",
		components: {
			BlockBox
		},
		mixins: [echartMixins],
		data() {
			return {
				item: {},
				colors: ['#f0792a', '#4b3eeb', '#247bf1', "#67e591", '#05d3f8', '#c0f02a', ],

				// mrhnDay: ['12/05', '12/06', '12/07', '12/08', '12/09'],
				//开始***************
				zrjtzlData: 58879638, //做题总减碳量
				ljjtzlData: 123456789, //做题总减碳量,
				timerRight:null
			}
		},
		mounted() {
			this.changeData()
			this.timerRight = setTimeout(() => {
			 this.changeData()
			}, 60000);
			
		},
		  beforeDestroy() {
		    clearTimeout(this.timerRight);
		  },
		methods: {
			changeData() {
				let _self = this
        rightDataNew().then(res => {
					_self.item = res.data;
					_self.$nextTick(function() {
						_self.drawCddjtj();
					});
				});
			},

			drawCddjtj() {
				this.chart = this.$echarts.init(document.getElementById("chartCddjtj"));
				//let data=[{name: "纸壳", value: 25},{name: "塑料", value: 36}];
				let data = this.item.allianceWeightList;
				let dateList = [];
				let dateValue = [];
				data.forEach(obj => {
					dateList.push(obj.name);
					dateValue.push(obj.value);
				})
				let option = {
					grid: {
						left: '3%',
						right: '3.5%',
						bottom: '3%',
						top: 20,
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',

						axisTick: {
							show: false,
						},
						data: dateList,
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#2da3ff'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisTick: {
							show: false,
						},
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#ffffff',
								fontSize: 10
							}
						}
					},
					series: [{
						name: '重量/千克',
						type: 'bar',
						barWidth: 9,
						data: dateValue,
						itemStyle: {
							normal: {
								label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: {
										//数值样式
										color: 'white',
										fontSize: 12,
									},
								},
								color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
											offset: 0,
											color: 'rgba(1, 108, 190, 1)', // 0% 处的颜色
										},
										{
											offset: 1,
											color: 'rgba(1, 38, 77, 1)' // 100% 处的颜色
										}
									],
									global: false, // 缺省为 false
								}
							}
						},

					}, ]
				};
				// echart图表自适应
				this.chart.setOption(option)
				window.addEventListener("resize", () => {
					this.chart.resize();
				});
			},
			formatter(number) {
				const numbers = number.toString().split('').reverse()
				const segs = []

				while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

				return segs.join(',').split('').reverse().join('')
			}


		}
	}
</script>

<style lang="scss">
	.com-right-content {
		min-height: 500px;
		padding: 14px 15px 15px 15px;
		box-sizing: border-box;

		.wzdjItem {
			width: 100%;
			height: 35px;
			line-height: 21px;
			padding: 11px 0;
			cursor: pointer;
		}

		.wzdjItem:hover {
			color: #006AF6;
		}

		.wzdjItem .leftNum {
			width: 21px;
			height: 21px;
			border-radius: 2px;
			background: #006AF6;
			line-height: 21px;
			text-align: center;
		}

		.wzdjItem .leftNum.one {
			background: -webkit-linear-gradient(top, rgba(255, 72, 0, 1), rgba(178, 0, 0, 1));
			/* Safari 5.1 - 6.0 */
			background: -o-linear-gradient(bottom, rgba(255, 72, 0, 1), rgba(178, 0, 0, 1));
			/* Opera 11.1 - 12.0 */
			background: -moz-linear-gradient(bottom, rgba(255, 72, 0, 1), rgba(178, 0, 0, 1));
			/* Firefox 3.6 - 15 */
			background: linear-gradient(to bottom, rgba(255, 72, 0, 1), rgba(178, 0, 0, 1));
		}

		.wzdjItem .leftNum.two {
			background: -webkit-linear-gradient(top, rgba(255, 174, 0, 1), rgba(167, 98, 0, 1));
			/* Safari 5.1 - 6.0 */
			background: -o-linear-gradient(bottom, rgba(255, 174, 0, 1), rgba(167, 98, 0, 1));
			/* Opera 11.1 - 12.0 */
			background: -moz-linear-gradient(bottom, rgba(255, 174, 0, 1), rgba(167, 98, 0, 1));
			/* Firefox 3.6 - 15 */
			background: linear-gradient(to bottom, rgba(255, 174, 0, 1), rgba(167, 98, 0, 1));
		}

		.shtjBox {
			height: 250px;

			& .swiper {
				height: 250px;
			}
		}
	}


	.hnzbLable {
		margin-top: 10px;
	}

	.hnzbLable .itemLable {
		line-height: 1;
		border: 1px solid;
		color: #2196f3;
		font-size: 12px;
		padding: 5px 7px;
		border-radius: 1px;
	}

	.hnzbLable .itemLable+.itemLable {
		margin-left: 7px;
	}

	.hnzbLable .itemLable.checked {
		background: #006AF6;
		color: #FFFFFF;
		border-color: #006AF6;
	}

	//调整区域****************
	.padding-top-10 {
		padding-top: 10px;
	}

	.videoBox {
		width: 100%;
		height: 205px;
		border-radius: 10rpx;
		overflow: hidden;

		iframe {
			width: 100%;
			height: 240px;
		}
	}

	.text-white {
		color: #FFFFFF;
	}

	.enTitle {
		padding-left: 3px;
		font-size: 13px;
		border-left: 2px solid #FFFFFF;
		margin-left: 7px;
		font-weight: lighter;
	}

	.base-titleBox-flex {
		padding-top: 9px;
		line-height: 1
	}

	.bigNum {
		font-weight: bold;
		line-height: 130px;
		font-size: 48px;
	}

	.text-green {
		color: #15ff15;
	}

	.midTitleBox {
		font-size: 15px;
		font-weight: bold;
		color: #FFFFFF;
		padding-left: 20px;
		box-sizing: border-box;
	}

	.base-font-16 {
		font-size: 16px;
	}

	.text-bold {
		font-weight: bold;
	}
</style>